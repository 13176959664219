import { RestApiClient } from '@shahadul-17/rest-api-client';
import { useEffect, useState } from 'react';
import { StringUtilities } from '../../utilities';
import Styles from "./CreateOrUpdateField.module.scss";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
/**
 * Creates or updates a module field.
 * @param {{
 * dynamicFieldId: String,
 * label: String,
 * inputType: String,
 * module: String,
 * data: String,
 * actions: Array<String>,
 * fieldFor: String,
 * key: String,
 * }} fieldData Object that shall contain all the information regarding a field.
 * @param {'CREATE'|'UPDATE'} mode (Optional) Mode of request can either be 'CREATE' or 'UPDATE'. Default value is 'CREATE'.
 * @returns Returns result.
 */
const createOrUpdateFieldAsync = async (fieldData, mode='CREATE') => {
  const restApiClient = RestApiClient.getInstance();
  const response = await restApiClient.sendSmartRequestAsync({
    routeName: mode === 'UPDATE' ? 'updateDynamicField' : 'createDynamicField',
    data: fieldData,
  });

  return response;
};

export const CreateOrUpdateField = ({ dynamicFieldId, fieldFor, fieldKey, fieldLabel, additionalData, fieldType, mode, onFormSubmit, onResponse, onCancel, }) => {
  // removes unnecessary whitespaces...
  const _dynamicFieldId = StringUtilities.getDefaultIfUndefinedOrNullOrEmpty(dynamicFieldId, undefined, true);

  const [view, setView] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [fieldData, setFieldData] = useState({
    dynamicFieldId: _dynamicFieldId,
    key: fieldKey,
    label: fieldLabel,
    inputType: fieldType,
    additionalData: additionalData,
  });

  const updateFieldData = entry => {
    setFieldData({
      ...fieldData,
      ...entry,
    });
  };

  const onInputValueChanged = event => {
    updateFieldData({
      [event.target.name]: event.target.value,
    });
  };

  const onFormSubmittedAsync = async event => {
    event.preventDefault();
    setLoading(true);
    setMessage('');

    const formData = new FormData(event.target);

    // if 'onFormSubmit' is a function, we shall execute it...
    if (typeof onFormSubmit === 'function') {
      await onFormSubmit({
        dynamicFieldId: _dynamicFieldId, mode, formData, event,
      });
    }

    createOrUpdateFieldAsync(formData, mode)
      .then(async response => {
        setLoading(false);

        response.status === 200 && setMessage(`Field ${mode === 'UPDATE' ? 'update' : 'create'}d successfully.`);
        response.status !== 200 && setMessage(`An error occurred while ${mode === 'UPDATE' ? 'updating' : 'creating'} field.`);

        // if 'onResponse' is a function, we shall execute it...
        if (typeof onResponse === 'function') {
          await onResponse({
            dynamicFieldId: _dynamicFieldId,
            mode, formData, event, response
          });
        }
      });
  };

  useEffect(() => {
    const _dynamicFieldId = StringUtilities.getDefaultIfUndefinedOrNullOrEmpty(dynamicFieldId, undefined, true);

    setFieldData({
      dynamicFieldId: _dynamicFieldId,
      key: fieldKey,
      label: fieldLabel,
      inputType: fieldType,
      additionalData: additionalData,
    });
  }, [dynamicFieldId, fieldKey, fieldLabel, fieldType, mode, additionalData]);

  return <div className={Styles.main}>
    <div className={Styles.header}>
      <h3>{mode === 'UPDATE' ? 'Update' : 'Create'} Field</h3>
      {view ? <KeyboardArrowUpIcon className={Styles.icon} onClick={()=>{setView(!view)}}/> : <KeyboardArrowDownIcon className={Styles.icon} onClick={()=>{setView(!view)}}/>}
    </div>
    
    <div className={Styles.container} style={!view ? {display: "none"} :{}}>
      {isLoading && <p>Please wait...</p>}
      {!isLoading && message.length ? <p>{message}</p> : null}
      {!isLoading && <form autoComplete='off' onSubmit={onFormSubmittedAsync}>
        <input type="hidden" name="dynamicFieldId" value={_dynamicFieldId} readOnly />
        <input type="hidden" name="fieldFor" value={fieldFor} readOnly />
        <div className={Styles.box}>
          <label htmlFor="label">Field type</label>
          <select name="inputType"
              value={fieldData.inputType ?? 'SINGLELINE_TEXT'}
              onChange={onInputValueChanged}>
            <option value="NUMERIC">Numeric</option>
            <option value="SINGLELINE_TEXT">Singleline Text</option>
            <option value="MULTILINE_TEXT">Multiline Text</option>
            <option value="CHECKBOX">Checkbox</option>
            <option value="SELECT">Select</option>
            <option value="FREE_SOLO_AUTOCOMPLETE">Autocomplete (Freestyle)</option>
            <option value="SINGLE_AUTOCOMPLETE">Autocomplete (Single Select)</option>
            <option value="MULTIPLE_AUTOCOMPLETE">Autocomplete (Multiple Select)</option>
            <option value="COMPLETE_DATE_DESKTOP">Date (Date Only - Desktop)</option>
            <option value="COMPLETE_DATE_MOBILE">Date (Date Only - Mobile)</option>
            <option value="TIME_ONLY_DATE">Date (Time Only)</option>
            <option value="YEAR_ONLY_DATE">Date (Year Only)</option>
            <option value="TIME_AND_DATE">Date and Time</option>
          </select>
        </div>

        {/* {fieldData.inputType === 'CHECKBOX' && <div className={Styles.box}>
          <label htmlFor='actions'>Actions</label>
          <textarea name='actions' value={fieldData.actions ?? ''}
            placeholder='Enter actions related to this field.'
            onChange={onInputValueChanged} />
        </div>} */}

        <div className={Styles.box}>
          <label htmlFor='additionalData'>Field Data</label>
          <input type='text' name='additionalData' value={fieldData.additionalData ?? ''}
            placeholder='e.g. Label 1-VALUE 1;Label 2-VALUE 2;...;Label N-VALUE N; etc.'
            onChange={onInputValueChanged} />
        </div>
        
        <div className={Styles.box}>
          <label htmlFor='label'>Field Label</label>
          <input type='text' name='label' value={fieldData.label ?? ''}
            placeholder='Enter a label for the field'
            onChange={onInputValueChanged}
            required />
        </div>
       
        <div className={Styles.box}> 
          <label htmlFor='key'>Field Key</label>
          <input type='text' name='key' value={fieldData.key ?? ''}
            placeholder='Enter a key for the field'
            onChange={onInputValueChanged}
            required />
        </div>
         
        <div className={Styles.btnContainer}>
            <button type='submit'>{mode === 'UPDATE' ? 'Update' : 'Create'}</button>
            {mode === 'UPDATE' && <button type='button' onClick={onCancel}>Cancel</button>}
        </div>
    </form>}
    </div>
  </div>;
};
