import { Fragment, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useInitiator } from '../../hooks';
import { StringUtilities } from '../../utilities';
import { DebouncedTextInput } from '../DebouncedTextInput';
import { IndeterminateProgressBar } from '../IndeterminateProgressBar';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import styles from './DataTable.module.css';

/**
 * @param {{
 * dataKeyPropertyName: String,
 * reRenderFlag: *,
 * showSearchBar?: Boolean,
 * limit: Number,
 * currentPage: Number,
 * totalPages: Number,
 * headers: Array<{
 * key: String,
 * label: String,
 * render?: Boolean,
 * }>,
 * data: Array<*>,
 * onBeforeDataColumnRender: Function,
 * onPageChange: Function,
 * onQueryChange: Function,
 * onQueryType: Function,
 * onSortingOrderChange: Function,
 * showIndexColumn: Boolean,
 * sortingOrders: *,
 * }} props 
 */
export const DataTable = ({
  dataKeyPropertyName, limit, currentPage, totalPages, headers, data,
  onBeforeDataColumnRender, onPageChange, onQueryChange, onQueryType,
  onSortingOrderChange, reRenderFlag, showSearchBar, showIndexColumn,
  sortingOrders,
}) => {
  const indexOffset = ((currentPage - 1) * limit) + 1;
  const [isLoading, setLoading] = useState(false);
  const [_reRenderFlag, setReRenderFlag] = useState(reRenderFlag ?? 0);
  const [_dataKeyPropertyName, setDataKeyPropertyName] = useState(dataKeyPropertyName ?? '');
  const [_currentPage, setCurrentPage] = useState(currentPage ?? 1);
  const [_totalPages, setTotalPages] = useState(totalPages ?? 1);
  const [_headers, setHeaders] = useState(headers ?? []);
  const [_data, setData] = useState(data ?? []);
  const [_sortingOrders, setSortingOrders,] = useState(sortingOrders ?? {});

  const onQueryChangedAsync = async event => {
    setLoading(true);

    if (typeof onQueryChange !== 'function') { return; }

    await onQueryChange(event);
  };

  const onQueryTypedAsync = async event => {
    if (typeof onQueryType !== 'function') { return; }

    await onQueryType(event);
  };

  const onPageChangedAsync = async ({ selected: selectedPage, }) => {
    setLoading(true);

    if (typeof onPageChange !== 'function') { return; }

    await onPageChange({
      selectedPage: selectedPage + 1,
    });
  };

  const onTableHeaderClickedAsync = async ({ key, label, sortable, event, }) => {
    if (!sortable) { return; }

    setLoading(true);

    const sortingOrder = _sortingOrders[key] === 'desc' ? 'asc' : 'desc';

    typeof onSortingOrderChange === 'function' && await onSortingOrderChange({ key, label, sortingOrder, });
  };

  useInitiator(async () => {
    const temporaryHeaders = [];
    showIndexColumn && temporaryHeaders.push({
      key: '__index__',
      label: '#',
      render: true,
    });
    temporaryHeaders.push(...headers);

    setLoading(false);
    setReRenderFlag(reRenderFlag ?? 0);
    setDataKeyPropertyName(dataKeyPropertyName ?? '');
    setCurrentPage(currentPage ?? 1);
    setTotalPages(totalPages ?? 1);
    setHeaders(temporaryHeaders ?? []);
    setData(data ?? []);
    setSortingOrders(sortingOrders ?? {});
  }, [reRenderFlag, dataKeyPropertyName, currentPage, totalPages, headers, data, sortingOrders,]);

  return <div>
    {showSearchBar && <DebouncedTextInput autoComplete='off' name='query' placeholder='Search here...' onChange={onQueryChangedAsync} onDebounce={onQueryTypedAsync} style={{ width: '100%', }} />}
    <IndeterminateProgressBar isVisible={isLoading} />

    <table className={styles.dataTable}>
      <tbody>
        <tr>
          {/* rendering table headers */}
          {_headers.map(({ key, label, render, sortable, }) => {
            if (render === false) { return <Fragment key={`header_${key}`}></Fragment>; }

            return <th key={`header_${key}`}
              style={{ cursor: sortable ? 'pointer' : '', }}
              onClick={async event => await onTableHeaderClickedAsync({ key, label, sortable, event, })}>
              {label}
              {sortable && _sortingOrders[key] === 'desc' && <KeyboardArrowUpIcon />}
              {sortable && _sortingOrders[key] !== 'desc' && <KeyboardArrowDownIcon />}
            </th>;
          })}
        </tr>

        {/* if no data found to be rendered */}
        {!_data.length && <tr>
          <td colSpan={_headers.length}>
            <p>No content found.</p>
          </td>
        </tr>}

        {/* rendering table data */}
        {_data.length ? _data.map((datum, index) => {
          const dataKey = datum[_dataKeyPropertyName];

          return <tr key={dataKey}>
            {_headers.map(({ key, label, render, }) => {
              if (render === false) { return <Fragment key={`data_${key}`}></Fragment>; }

              let currentDataColumnValue = datum[key];

              if (key === '__index__') {
                currentDataColumnValue = index + indexOffset;
              }

              const returnValue = typeof onBeforeDataColumnRender === 'function' ? onBeforeDataColumnRender({
                index,
                dataKey,
                currentDataColumnLabel: label,
                currentDataColumnPropertyName: key,
                currentDataColumnValue,
                datum,
              }) : undefined;
              const dataColumnValue = returnValue?.dataColumnValue ?? currentDataColumnValue;
              const truncatedDataColumnValue = StringUtilities.truncate(dataColumnValue, 8, 8);

              return <td key={`data_${key}`} title={StringUtilities.isUndefinedOrNullOrEmpty(dataColumnValue) ? undefined : dataColumnValue}>{truncatedDataColumnValue ?? ''}</td>;
            })}
          </tr>;
        }) : null}
      </tbody>
    </table>

    {/* table pagination */}
    <ReactPaginate
      breakLabel='...'
      nextLabel='>'
      previousLabel='<'
      onPageChange={onPageChangedAsync}
      pageRangeDisplayed={1}
      pageCount={_totalPages}
      forcePage={_currentPage - 1}
      pageClassName={styles.recentPageItem}
      pageLinkClassName={styles.recentPage}
      previousClassName='page-item'
      previousLinkClassName={styles.prevPage}
      nextClassName='page-item'
      nextLinkClassName={styles.nextPage}
      breakClassName='page-item'
      breakLinkClassName='page-link'
      containerClassName={styles.pagination}
      activeClassName={styles.activePage}
    />
  </div>;
};
