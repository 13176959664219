import { HttpMethod } from '@shahadul-17/http-client';
import { RestApiClient } from '@shahadul-17/rest-api-client';
import { Fragment, useState } from 'react';
import { configuration } from '../../configuration';
import { ImageUpload } from '../ImageUpload';
import { ArrayUtilities } from '../../utilities';
import { CreateOrUpdateField } from '../CreateOrUpdateField';
import { DynamicField } from '../DynamicField';
import { useInitiator } from '../../hooks';

import Styles from "./CreateOrUpdateProperty.module.scss";

const DEFAULT_PROPERTY_DATA = {
  sku: '',
  priority: 100,
  title: '',
  description: '',
  keywords: [],
  badges: [],
  sizeInSqft: 0,
  beds: 0,
  baths: 0,
  floorLocated: 0,
  hasLift: false,
  lifts: 0,
  hasBalcony: false,
  balconies: 0,
  hasParking: false,
  parkingCapacity: 0,
  hasGas: false,
  gasType: '',
  buildYear: new Date().toString(),
  propertyType: '',
  location: '',
};

/**
 * Creates or updates a product.
 * @param {any} product Object that shall contain all the information of a product.
 * @returns Returns response.
 */
const createOrUpdateProductAsync = async product => {
  let buildYear = undefined;

  if (typeof product.buildYear === 'string' && product.buildYear.length > 0) {
    buildYear = new Date(product.buildYear).getFullYear();
  }

  const restApiClient = RestApiClient.getInstance();
  const url = `${configuration.restApiClient.host}/api/v1.0/products/${product.productId ? product.productId : ''}`
  const response = await restApiClient.sendRequestAsync({
    method: product.productId ? HttpMethod.Put : HttpMethod.Post,
    url: url,
    body: product,
    headers: {
      authorization: restApiClient.getTokenProvider().getAccessToken(),
    },
  });

  return response;
};

/**
 * @param {{
 * productId?: String,
 * includeDynamicFields?: Boolean,
 * }} options
 * @returns 
 */
const retrieveProductAndDynamicFieldsAsync = async (options) => {
  const restApiClient = RestApiClient.getInstance();
  const contents = [];

  options.productId && contents.push(`products:${options.productId}`);
  options.includeDynamicFields !== false && contents.push('dynamicFields');

  const response = await restApiClient.sendSmartRequestAsync({
    routeName: "getMiscellaneousContents",
    data: {
      ignoreCache: true,
      contents: contents.join(','),
      dynamicFieldFor: "products",
    },
    requestTags: ['DECODE_UNICODE_CHARACTERS'],
  });

  return response;
};

export const CreateOrUpdateProperty = ({ mode, propertyId, }) => {
  const [propertyRetrievalStatus, setPropertyRetrievalStatus] = useState(-1);
  const [message, setMessage] = useState('');
  const [fieldMode, setFieldMode] = useState('CREATE');
  const [selectedPropertyField, setSelectedPropertyField] = useState({});
  const [propertyFields, setPropertyFields] = useState([]);
  const [property, setProperty] = useState({ ...DEFAULT_PROPERTY_DATA });

  const updateProperty = entry => {
    setProperty({
      ...property,
      ...entry,
    });
  };

  const updatePropertyImages = uploadedImages => {
    const _uploadedImages = [];

    for (const uploadedImage of uploadedImages) {
      _uploadedImages.push({
        fileId: uploadedImage.fileId,
        url: uploadedImage.url,
      });
    }

    const images = ArrayUtilities.addElements(_uploadedImages, property.images);

    updateProperty({
      images: images,
    });
  };

  const onInputValueChanged = ({ dynamicFieldId, fieldLabel, fieldType, fieldKey, fieldValue, event, }) => {
    updateProperty({
      [fieldKey]: fieldValue,
    });
  };

  const onFormSubmittedAsync = async event => {
    event.preventDefault();

    if (mode === 'UPDATE' && !property.productId) { return setMessage('Unable to update property data as the property was not properly loaded...'); }

    setMessage('Please wait...');

    const response = await createOrUpdateProductAsync(property);

    if (response.status !== 200) {
      setMessage(response.jsonData.message ?? `An error occurred while ${mode === 'UPDATE' ? 'updating' : 'creating'} property (${response.status}).`);

      return;
    }

    setMessage(response.jsonData.message);
    setProperty(response.jsonData.data.product ?? property);
  };

  const onImagesUploadedAsync = async ({ images, response, }) => {
    if (response.status !== 200) { return; }

    const uploadedImages = response.jsonData.data.files;

    updatePropertyImages(uploadedImages);
  };

  const onRemoteImageRemovedAsync = async ({ type, image, remoteImages, event, }) => {
    updateProperty({ images: remoteImages, });
  };

  const onCancelled = () => {
    setSelectedPropertyField({});
    setFieldMode('CREATE');
  };

  const onEditFieldClickedAsync = propertyField => {
    setSelectedPropertyField(propertyField);
    setFieldMode('UPDATE');
  };

  const onDeleteFieldClickedAsync = ({ fieldId, formData, event, response, }) => {
    if (response.status !== 200) { return; }

    const _propertyFields = ArrayUtilities.remove('dynamicFieldId', fieldId, propertyFields);

    setPropertyFields(_propertyFields);
  };

  const onResponseReceivedAsync = async ({ mode, moduleId, dynamicFieldId, formData, event, response, }) => {
    if (response.status !== 200) { return; }

    let _propertyFields;

    // updates field...
    if (mode === 'UPDATE') {
      const propertyField = response.jsonData.data.dynamicField;

      _propertyFields = ArrayUtilities.updateElement('dynamicFieldId', dynamicFieldId, propertyField, propertyFields);

      // sets mode to create...
      setSelectedPropertyField({});
      setFieldMode('CREATE');
    } else {
      const propertyField = response.jsonData.data.dynamicField;

      _propertyFields = ArrayUtilities.addElement(propertyField, propertyFields);
    }

    // sets fields...
    setPropertyFields(_propertyFields);
  };

  useInitiator(async () => {
    setMessage('Please wait...');

    const response = await retrieveProductAndDynamicFieldsAsync({
      productId: mode === 'UPDATE' && propertyId ? propertyId : undefined,
      includeDynamicFields: true,     // <-- true is default value...
    });

    if (response.status !== 200) {
      setMessage(`An error occurred while retrieving data. (${response.status})`);

      return;
    }

    setMessage('');

    const propertyFields = response.jsonData.data.dynamicFields ?? [];

    setPropertyFields(propertyFields);

    if (mode !== 'UPDATE' || !propertyId) { return; }

    // if product was not found...
    if (!response.jsonData.data.product) {
      setMessage('Requested product was not found in our inventory (404)');

      return;
    }

    setProperty(response.jsonData.data.product ?? property);
    setPropertyRetrievalStatus(response.status);
  }, [propertyId]);

  return <div className={Styles.container}>
    <div className={Styles.main}>
      {message && <p>{message}</p>}
      <CreateOrUpdateField
        mode={fieldMode}
        fieldFor='products'
        dynamicFieldId={selectedPropertyField.dynamicFieldId}
        fieldLabel={selectedPropertyField.label}
        fieldKey={selectedPropertyField.key}
        fieldType={selectedPropertyField.inputType}
        additionalData={selectedPropertyField.additionalData}
        onResponse={onResponseReceivedAsync}
        onCancel={onCancelled}
      />
      {mode === 'UPDATE' && ![-1, 200].includes(propertyRetrievalStatus) && <p>Sorry, we could not retrieve the requested property ({propertyRetrievalStatus}).</p>}
      {(mode !== 'UPDATE' || (mode === 'UPDATE' && propertyRetrievalStatus === 200)) && <>
        <form autoComplete='off' onSubmit={onFormSubmittedAsync}>
          {propertyFields.map(propertyField => {
            return <Fragment key={propertyField.dynamicFieldId}>
              <DynamicField
                fieldId={propertyField.dynamicFieldId}
                fieldKey={propertyField.key}
                fieldType={propertyField.inputType}
                fieldLabel={propertyField.label}
                fieldData={propertyField.additionalData}
                fieldValue={property[propertyField.key]}
                onValueChange={onInputValueChanged}
                onFieldEdit={() => onEditFieldClickedAsync(propertyField)}
                onFieldDelete={onDeleteFieldClickedAsync} />
              <br />
            </Fragment>;
          })}
          <br />
          <label>Upload Images</label>
          <ImageUpload propertyImages={property.images}
            onUpload={onImagesUploadedAsync}
            onRemoteImageRemove={onRemoteImageRemovedAsync} />

          <button className={Styles.btn} type='submit'>{mode === 'UPDATE' ? 'Save' : 'Create'}</button>
        </form>
      </>
      }
    </div>

  </div>;
};
