import { Autocomplete, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RestApiClient } from '@shahadul-17/rest-api-client';
import { useEffect, useState } from 'react';
import { Spinner } from '..';
import { useInitiator } from '../../hooks';
import Styles from "./CreateOrUpdateUser.module.scss";

const GENDERS = [
  { label: 'None', value: 'NONE' },
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
];

const retrieveUserAsync = async userId => {
  const restApiClient = RestApiClient.getInstance();
  const response = await restApiClient.sendSmartRequestAsync({
    routeName: 'getUserById',
    data: { userId: userId, },
  });

  return response;
};

const createOrUpdateUserAsync = async (userData, mode = 'CREATE') => {
  const restApiClient = RestApiClient.getInstance();
  const response = await restApiClient.sendSmartRequestAsync({
    routeName: mode === 'UPDATE' ? 'updateUser' : 'createUser',
    data: userData,
  });

  return response;
};

export const CreateOrUpdateUser = ({ mode, userId, }) => {
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState(500);
  const [message, setMessage] = useState('');
  const [userData, setUserData] = useState({});

  const updateUserData = entry => {
    setUserData({
      ...userData,
      ...entry,
    });
  };

  const onInputValueChangedAsync = async event => {
    updateUserData({ [event.target.name]: event.target.value, });
  };

  const onFormSubmittedAsync = async event => {
    event.preventDefault();

    const response = await createOrUpdateUserAsync({
      userId: userId,
      ...userData,
    }, mode);

    if (response.status !== 200) { return; }

    setUserData(response.jsonData.data.user);
  };

  useInitiator(async () => {
    if (mode !== 'UPDATE' || !userId) { return; }

    setLoading(true);

    const response = await retrieveUserAsync(userId)
    
    setStatus(response.status);
    setMessage(response.message);
    setLoading(false);

    if (response.status !== 200) {
      setUserData(null);

      return;
    }

    setUserData(response.jsonData.data.user);
  }, [mode, userId]);

  return <div className={Styles.main}>
    {isLoading && <Spinner />}
    {!isLoading && status !== 200 && <p className={Styles.message}>{message}</p>}
    {!isLoading && !(mode === 'UPDATE' && status !== 200 && userData === null) && 
    <form className={Styles.form} autoComplete='off' onSubmit={onFormSubmittedAsync}>
      <label htmlFor="name">Name</label>
      <input className={Styles.input} type="text" name="name" value={userData.name ?? ''} onChange={onInputValueChangedAsync} />

      {/* <label htmlFor="username">Username</label>
      <input className={Styles.input} type="text" name="username" value={userData.username ?? ''} onChange={onInputValueChangedAsync} /> */}

      <label htmlFor="email">Email</label>
      <input className={Styles.input} type="email" name="email" value={userData.email ?? ''} onChange={onInputValueChangedAsync} />

      <label htmlFor="mobile">Mobile</label>
      <input className={Styles.input} type="tel" name="mobile" value={userData.mobile ?? ''} onChange={onInputValueChangedAsync} />

      {mode !== 'UPDATE' && <>
        <label htmlFor="password">Password</label>
        <input className={Styles.input} type="password" name="password" value={userData.password ?? ''} onChange={onInputValueChangedAsync} />
      </>}

      <label htmlFor="gender">Gender</label>
      <Autocomplete
        value={userData.gender ?? ''}
        size='small'
        multiple={false}
        options={GENDERS}
        getOptionLabel={option => option.label ?? ''}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={async (_, value) => await onInputValueChangedAsync({ target: { name: 'gender', value: value, } })}
        renderInput={params => <TextField {...params} label={''} />} />

      <label htmlFor="occupation">Occupation</label>
      <input className={Styles.input} type="text" name="occupation" value={userData.occupation ?? ''} onChange={onInputValueChangedAsync} />

      <label htmlFor="dateOfBirth">Date of Birth</label>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label=''
          value={userData.dateOfBirth ?? ''}
          inputFormat='dd/MM/yyyy'
          onChange={async (value, keyboardInputValue) => await onInputValueChangedAsync({ target: { name: 'dateOfBirth', value, keyboardInputValue, } })}
          renderInput={params => <TextField {...params} />} />
      </LocalizationProvider>

      <label htmlFor="status">Status</label>
      <Autocomplete
        value={userData.status ?? {}}
        size='small'
        multiple={false}
        options={[{ label: "Active", value: "ACTIVE" }]}
        getOptionLabel={option => option.label ?? ''}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={async (_, value) => await onInputValueChangedAsync({ target: { name: 'status', value: value, } })}
        renderInput={params => <TextField {...params} label={''} />} />

      {/* <label htmlFor="roles">Access Roles</label>
      <Autocomplete
        value={userData.roles ?? []}
        size='small'
        multiple={true}
        // this will be retrieved from backend...
        options={[{ label: "Hello", value: "World" }]}
        getOptionLabel={option => option.label ?? ''}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={async (_, value) => await onInputValueChangedAsync({ target: { name: 'roles', value: value, } })}
        renderInput={params => <TextField {...params} label={''} />} /> */}

      <label htmlFor="Tags">Tags</label>
      <Autocomplete
        size='small'
        freeSolo={true}
        value={userData.tags ?? []}
        options={[]}
        multiple={true}
        autoComplete={false}
        onChange={async (_, value) => await onInputValueChangedAsync({ target: { name: 'tags', value: value ?? [], } })}
        renderInput={params => <TextField {...params} label={''} />} />

      <label htmlFor="address">Address</label>
      <textarea className={Styles.input} name="address" value={userData.address ?? ''} onChange={onInputValueChangedAsync} />

      <label htmlFor="description">Description</label>
      <textarea className={Styles.input} name="description" value={userData.description ?? ''} onChange={onInputValueChangedAsync} />

      <button className={Styles.btn} type='submit'>{mode === 'UPDATE' ? 'Save' : 'Create'}</button>
    </form>}
  </div>;
};
